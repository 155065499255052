


@font-face {
  font-family: Lato;
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url("https://fonts.shopifycdn.com/lato/lato_n6.8f129fde40f203553b1c63523c8c34e59550404e.woff2?h1=ZG9nLXUuZXU&h2=ZG9nLXUuZXM&h3=ZG9nLXUuZnI&h4=ZG9ndS5zdG9yZQ&h5=ZG9nLXUucHQ&h6=ZG9ndS5wdA&hmac=79dcd795a03b40f7c4b5c001fe5dda5ef22fd5b426776660aaa25fc90e16ceca") format("woff2"),
       url("https://fonts.shopifycdn.com/lato/lato_n6.822b168fbb902b52be8d60ec7a9fd5122a4894fe.woff?h1=ZG9nLXUuZXU&h2=ZG9nLXUuZXM&h3=ZG9nLXUuZnI&h4=ZG9ndS5zdG9yZQ&h5=ZG9nLXUucHQ&h6=ZG9ndS5wdA&hmac=37474bec0ae469a04e65166e0c392c39e4776e0b456c1e6220ad5a487c5471a2") format("woff");
}

@font-face {
  font-family: Lato;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("https://fonts.shopifycdn.com/lato/lato_n4.c86cddcf8b15d564761aaa71b6201ea326f3648b.woff2?h1=ZG9nLXUuZXU&h2=ZG9nLXUuZXM&h3=ZG9nLXUuZnI&h4=ZG9ndS5zdG9yZQ&h5=ZG9nLXUucHQ&h6=ZG9ndS5wdA&hmac=b7d418762cdcbad1642795c8ee969c00b3a4da874dd2a7f00fd48464cbc078e6") format("woff2"),
       url("https://fonts.shopifycdn.com/lato/lato_n4.e0ee1e2c008a0f429542630edf70be01045ac5e9.woff?h1=ZG9nLXUuZXU&h2=ZG9nLXUuZXM&h3=ZG9nLXUuZnI&h4=ZG9ndS5zdG9yZQ&h5=ZG9nLXUucHQ&h6=ZG9ndS5wdA&hmac=ffa01e4aeb8d9e1b13e3012f05d628dc022942d6bbc4cce0cfef5927871335c2") format("woff");
}

@font-face {
  font-family: Lato;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("https://fonts.shopifycdn.com/lato/lato_n4.c86cddcf8b15d564761aaa71b6201ea326f3648b.woff2?h1=ZG9nLXUuZXU&h2=ZG9nLXUuZXM&h3=ZG9nLXUuZnI&h4=ZG9ndS5zdG9yZQ&h5=ZG9nLXUucHQ&h6=ZG9ndS5wdA&hmac=b7d418762cdcbad1642795c8ee969c00b3a4da874dd2a7f00fd48464cbc078e6") format("woff2"),
       url("https://fonts.shopifycdn.com/lato/lato_n4.e0ee1e2c008a0f429542630edf70be01045ac5e9.woff?h1=ZG9nLXUuZXU&h2=ZG9nLXUuZXM&h3=ZG9nLXUuZnI&h4=ZG9ndS5zdG9yZQ&h5=ZG9nLXUucHQ&h6=ZG9ndS5wdA&hmac=ffa01e4aeb8d9e1b13e3012f05d628dc022942d6bbc4cce0cfef5927871335c2") format("woff");
}



  @font-face {
  font-family: Lato;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("https://fonts.shopifycdn.com/lato/lato_n7.f0037142450bd729bdf6ba826f5fdcd80f2787ba.woff2?h1=ZG9nLXUuZXU&h2=ZG9nLXUuZXM&h3=ZG9nLXUuZnI&h4=ZG9ndS5zdG9yZQ&h5=ZG9nLXUucHQ&h6=ZG9ndS5wdA&hmac=de79a6fdf808373bc800659d00cbd106fc14b3fa6266a52c0f2d720887f2dd9e") format("woff2"),
       url("https://fonts.shopifycdn.com/lato/lato_n7.50161a3d4f05d049b7407d86c5a8834e4d6e29bf.woff?h1=ZG9nLXUuZXU&h2=ZG9nLXUuZXM&h3=ZG9nLXUuZnI&h4=ZG9ndS5zdG9yZQ&h5=ZG9nLXUucHQ&h6=ZG9ndS5wdA&hmac=d99363a7a5911b6de44742cd93c46a02ebcbb4d683e74764a4cc904b1183edba") format("woff");
}




  @font-face {
  font-family: Lato;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("https://fonts.shopifycdn.com/lato/lato_n5.10bc1bd2bdb4c0ac35363745e79901b69a0be29a.woff2?h1=ZG9nLXUuZXU&h2=ZG9nLXUuZXM&h3=ZG9nLXUuZnI&h4=ZG9ndS5zdG9yZQ&h5=ZG9nLXUucHQ&h6=ZG9ndS5wdA&hmac=c47edd80f82df377af8bfd40d2527cc5ba6e64f39d1e599d67aacc8170ed682b") format("woff2"),
       url("https://fonts.shopifycdn.com/lato/lato_n5.f7f0195bc06ce869daac4bf73d242fb9b37734db.woff?h1=ZG9nLXUuZXU&h2=ZG9nLXUuZXM&h3=ZG9nLXUuZnI&h4=ZG9ndS5zdG9yZQ&h5=ZG9nLXUucHQ&h6=ZG9ndS5wdA&hmac=70594800027914c7522407d95ea70c2582d2eeef94be45f2f58e9700301b22db") format("woff");
}




  @font-face {
  font-family: Lato;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("https://fonts.shopifycdn.com/lato/lato_n5.10bc1bd2bdb4c0ac35363745e79901b69a0be29a.woff2?h1=ZG9nLXUuZXU&h2=ZG9nLXUuZXM&h3=ZG9nLXUuZnI&h4=ZG9ndS5zdG9yZQ&h5=ZG9nLXUucHQ&h6=ZG9ndS5wdA&hmac=c47edd80f82df377af8bfd40d2527cc5ba6e64f39d1e599d67aacc8170ed682b") format("woff2"),
       url("https://fonts.shopifycdn.com/lato/lato_n5.f7f0195bc06ce869daac4bf73d242fb9b37734db.woff?h1=ZG9nLXUuZXU&h2=ZG9nLXUuZXM&h3=ZG9nLXUuZnI&h4=ZG9ndS5zdG9yZQ&h5=ZG9nLXUucHQ&h6=ZG9ndS5wdA&hmac=70594800027914c7522407d95ea70c2582d2eeef94be45f2f58e9700301b22db") format("woff");
}







  @font-face {
  font-family: Lato;
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url("https://fonts.shopifycdn.com/lato/lato_i4.7307831c0d06d264a76f2f7310f8a29d0507d6d0.woff2?h1=ZG9nLXUuZXU&h2=ZG9nLXUuZXM&h3=ZG9nLXUuZnI&h4=ZG9ndS5zdG9yZQ&h5=ZG9nLXUucHQ&h6=ZG9ndS5wdA&hmac=303bc96d3e2fd2eb42c3d89e15d158bd18efe64684a358a7e45c02d25076cf79") format("woff2"),
       url("https://fonts.shopifycdn.com/lato/lato_i4.fb695d0d68d5b174347edaf543726b1903b57796.woff?h1=ZG9nLXUuZXU&h2=ZG9nLXUuZXM&h3=ZG9nLXUuZnI&h4=ZG9ndS5zdG9yZQ&h5=ZG9nLXUucHQ&h6=ZG9ndS5wdA&hmac=c687fb832e77cb0b0894461bb10fbdc0a29afd5952b6dafbc87bb023ddaf637f") format("woff");
}



  @font-face {
  font-family: Lato;
  font-weight: 500;
  font-style: italic;
  font-display: swap;
  src: url("https://fonts.shopifycdn.com/lato/lato_i5.34a1281e461a2dbf339c6340939aff4fa5a02ce4.woff2?h1=ZG9nLXUuZXU&h2=ZG9nLXUuZXM&h3=ZG9nLXUuZnI&h4=ZG9ndS5zdG9yZQ&h5=ZG9nLXUucHQ&h6=ZG9ndS5wdA&hmac=46eb3e7244c445bfd2400d3091add9e246149c5a6db52c9b4c5e370f2eef1a2f") format("woff2"),
       url("https://fonts.shopifycdn.com/lato/lato_i5.dfa979b4c6c5c096432f0742c70ca73f045a9465.woff?h1=ZG9nLXUuZXU&h2=ZG9nLXUuZXM&h3=ZG9nLXUuZnI&h4=ZG9ndS5zdG9yZQ&h5=ZG9nLXUucHQ&h6=ZG9ndS5wdA&hmac=04c90ed56aba6812dca11917c302f89f650fcb6aea81c19af7133e0be43eb4ae") format("woff");
}


